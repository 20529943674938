import React from 'react';
import {graphql} from "gatsby";
import {Helmet} from "react-helmet";
import {SEO} from "../components/seo";
import Footer from "../components/Footer";
import Header from "../components/Header";
import ogimage from "../images/vault-og-image.png";
import HeroSection from "../contents/Vaults/HeroSection";
import VoiceSection from "../contents/Home/voice-section";
import OverviewSection from "../contents/Vaults/OverviewSection";
import FourLogosSection from "../contents/Vaults/FourLogosSection";
import ThreeImagesSection from "../contents/Vaults/ThreeImagesSection";
import KeyComponentsSection from "../contents/Vaults/KeyComponentsSection";
import TitleWithImageAndBodySection from "../contents/Vaults/TitleWithImageAndBodySection";

const OracleNetwork = ({data}) => {
    const pageTitle = data.contentfulPage.title;
    const filterContentfulSections = (filter, onlyOne = true) => {
        console.log({data})
        const section = data.contentfulPage.sections.filter(function (section) {
            return section.internal.type === filter;
        });
        if (onlyOne) {
            return (section[0]);
        }

        return section;
    }

    return (
        <div>
            <Helmet
                meta={[
                    {
                        property: `og:title`,
                        content: 'Inter Protocol - ' + pageTitle,
                    },
                    {
                        property: `twitter:title`,
                        content: 'Inter Protocol - ' + pageTitle,
                    },
                    {
                        property: `og:type`,
                        content: `website`,
                    },
                    {
                        property: `twitter:image`,
                        content: ogimage,
                    },
                    {
                        property: `og:image`,
                        content: ogimage,
                    },
                    {
                        property: `twitter:card`,
                        content: `summary_large_image`,
                    },
                ]}
            >
                <title>Inter Protocol - {pageTitle}</title>
            </Helmet>
            <Header/>
            <div className={'section vault-hero-section relative auto-height'}>
                <HeroSection disableVideo={true} contentful={filterContentfulSections('ContentfulHeader')}/>
            </div>
            <div className={'section whats-different-section fp-auto-height'}>
                <OverviewSection contentful={filterContentfulSections('ContentfulCallToActionWithInfoBlock')}/>
            </div>
            <div className={'section white-section fp-auto-height-responsive'}>
                <div className={'use-cases-section'}>
                    <KeyComponentsSection
                        contentful={filterContentfulSections('ContentfulKeyComponentsSection', false)[0]}
                        enableTitle={false}
                     />
                </div>
                <div className={'py-20'}>
                    <FourLogosSection fullWidth={true} contentful={filterContentfulSections('ContentfulHowItWorksSection')} />
                </div>
                <div className={'title-with-image-and-body-section pt-20'}>
                    <TitleWithImageAndBodySection contentful={filterContentfulSections('ContentfulTitleWithImageAndBodySection')} />
                </div>
                <div className={'voice-section'}>
                    <VoiceSection contentful={filterContentfulSections('ContentfulVoiceSection')}/>
                </div>
                <div className={'footer-section'}>
                    <Footer/>
                </div>
            </div>
        </div>
    )
}

export const Head = () => (
    <SEO />
)

export const query = graphql`
    {
        contentfulPage(contentful_id: {eq: "5mRBZx6lmYhcY8dBPoDVUf"}) {
            title
            sections {
                ... on ContentfulHeader {
                    title
                    subtitle {
                        raw
                    }
                    callToActionText
                    callToActionUrl
                    isCallToActionDisabled
                    extraTitle
                    extraSubtitle
                    internal {
                        type
                    }
                }
                ... on ContentfulCallToActionWithInfoBlock {
                    id
                    title
                    node_locale
                    callToActionUrl
                    callToActionText
                    internal {
                        type
                    }
                    body {
                        raw
                    }
                }
                ... on ContentfulVoiceSection {
                    id
                    body {
                        raw
                    }
                    title
                    callToActionUrl
                    callToActionText
                    callToActionUrlTwo
                    callToActionTextTwo
                    callToActionUrlThree
                    callToActionTextThree
                    internal {
                        type
                    }
                }
                ... on ContentfulHowItWorksSection {
                    id
                    title
                    internal {
                        type
                    }
                    elements {
                        title
                        body {
                            raw
                        }
                        internal {
                            type
                        }
                    }
                    subtitle {
                        raw
                    }
                }
                ... on ContentfulKeyComponentsSection {
                    id
                    contentful_id
                    title
                    body {
                        raw
                    }
                    elements {
                        title
                        body {
                            raw
                        }
                        image {
                            url
                            title
                        }
                    }
                    internal {
                        type
                    }
                }
                ... on ContentfulTitleWithImageAndBodySection {
                    title
                    body {
                        raw
                    }
                    image {
                        url
                        title
                    }
                    internal {
                        type
                    }
                }
            }
        }
    }
`

export default OracleNetwork;
